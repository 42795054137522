@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
$font-family-base: "Open Sans", sans-serif;

$primary: #0b599e;
$success: #2eab88;
$secondary: #1084eb;
$light: #e2f0fd;

$body-bg: #f1f2f3;

$headings-margin-bottom: 1.5rem;
$headings-font-weight: 700;
$border-radius: 0.6rem;

$input-btn-padding-y: 0.8rem !default;
$input-btn-padding-x: 1.2rem !default;
$btn-padding-x: 2rem;
// $input-btn-font-family:       null !default;
// $input-btn-font-size:         $font-size-base !default;
// $input-btn-line-height:       $line-height-base !default;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

@import "tooltip";

html {
	font-size: 13px;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.app {
	&-header {
		background: #fff;
		position: relative;
		padding: 15px 0;
		@include media-breakpoint-up(sm) {
			padding: 30px 0;
		}
		.logo {
			width: 80px;
			display: block;
			margin: 0 auto;
			@include media-breakpoint-up(sm) {
				width: 100px;
				position: absolute;
				right: $grid-gutter-width;
				top: 50%;
				transform: translatey(-50%);
			}
		}
		.help {
			display: block;
			position: absolute;
			right: 8px;
			top: 8px;
			@include media-breakpoint-up(sm) {
				top: 50%;
				left: $grid-gutter-width;
				right: auto;
				transform: translatey(-50%);
			}
			svg {
				width: 20px;
				height: 20px;
				fill: $gray-500;
				@include media-breakpoint-up(sm) {
					width: 30px;
					height: 30px;
				}
			}
			&:hover {
				svg {
					fill: $primary;
				}
			}
		}
	}
	&-main {
		flex: 1;
		padding: 30px 0;
		@include media-breakpoint-up(sm) {
			padding: 50px 0;
		}
	}
	&-footer {
		font-size: $font-size-sm;
		color: $white;
		h5{
			font-size: 1.1rem;
		}
		a {
			color: $white;
		}
	}
}

.panel {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	background: #fff;
	max-width: 360px;
	width: 100%;
	transform: translateX(-100%);
	transition: transform 0.2s;
	z-index: 999;
	overflow-y: auto;

	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 1rem 1.5rem;
	}

	&.open {
		transform: translateX(0);
	}

	.close {
		font-size: 1rem;
		line-height: 1;
		text-transform: uppercase;
		font-weight: bold;
	}
}

.steps {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
	.item-step {
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: center;
		border: 2px solid $secondary;
		border-radius: 50%;
		color: $primary;
		font-weight: bold;
		opacity: 0.5;
		transform: scale(0.8);
		transition: opacity 0.2s, transform 0.2s;
		&.active {
			opacity: 1;
			transform: scale(1);
		}
	}
	.item-step,
	.item-icon {
		margin: 0 10px;
	}
	.item-sep {
		width: 20px;
		height: 2px;
		background: $gray-400;
	}

	.item-step,
	.icon {
		width: 30px;
		height: 30px;
	}

	@include media-breakpoint-up(md) {
		margin-top: 0;
		.item-step,
		.icon {
			width: 50px;
			height: 50px;
		}
		.item-sep {
			width: 50px;
		}
	}
}

.card {
	background: #fff;
	padding: 30px;
	border-radius: 20px;
	box-shadow: $box-shadow-lg;
	margin: 0 auto;
	&-sm {
		max-width: 420px;
	}
	&-sidebar {
		border-bottom: 1px solid $border-color;
		padding: 30px 30px 20px 30px;
		@include media-breakpoint-up(sm) {
			background: $light;
			border-top-left-radius: 16px;
			border-bottom-left-radius: 16px;
			height: 100%;
		}
	}
	&-content {
		padding: 30px;
	}
	&.step2,
	&.step3 {
		padding: 0;
		@include media-breakpoint-up(sm) {
			padding: 5px;
		}
		max-width: 800px;
	}
}

.payment {
	&-success-icon,
	&-failed-icon {
		width: 100px;
		height: 100px;
		margin-bottom: $grid-gutter-width;
		@include media-breakpoint-up(sm) {
			width: 150px;
			height: 150px;
		}
	}

	&-success-icon {
		* {
			fill: $success;
		}
	}

	&-failed-icon {
		* {
			fill: $danger;
		}
	}
}

.card-logos {
	svg {
		height: 18px;
		width: auto;
		+ svg {
			margin-left: 10px;
		}
	}
}

.form-footer {
	margin-top: 2rem;

	@include media-breakpoint-up(sm) {
		display: flex;
		justify-content: space-between;
	}

	@include media-breakpoint-down(xs) {
		.btn {
			display: block;
			width: 100%;

			+ .btn {
				margin-top: 1rem;
			}
		}
	}
}

.price-summary {
	.list-group-item {
		padding-left: 0;
		padding-right: 0;
		display: flex;
		justify-content: space-between;
	}
}

.spinner {
	width: 100vw;
	height: 100vh;
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	background: rgba(white, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;

	transition: opacity 0.5s linear;

	circle {
		fill: #0b599e;
	}

	&.show {
		opacity: 1;
	}
}

.tooltip-help {
	display: inline-block;
	padding: 0 0.5rem;

	svg {
		width: 18px;
		height: 18px;
		fill: $gray-500;
	}
	&:hover {
		svg {
			fill: $primary;
		}
	}
}
